import React from "react"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

import "normalize.css"
import "../assets/css/main.css"

const Layout = props => {
  return (
    <>
      <Navbar />
      {props.children}
      <Footer />
    </>
  )
}

export default Layout
